@import "../../variables.scss";

.user-reset__container {
  max-width: 60.5rem;
  width: 95%;
  margin: 0 auto;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.user-reset {
  width: 100%;

  &__logo {
    text-align: center;
    margin-bottom: 2rem;

    img {
      width: 35rem;
      height: 5rem;
    }
  }

  &__heading {
    h1 {
      font-size: 4.8rem;
      text-align: center;
      margin-bottom: 4rem;
    }
  }
}
