@import "../../variables.scss";

.user-verify__container {
  max-width: 60.5rem;
  width: 95%;
  margin: 0 auto;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.user-verify {
  width: 100%;

  &__logo {
    text-align: center;
    margin-bottom: 5rem;

    img {
      width: 35rem;
      height: 5rem;
    }
  }
}
