@import "../../variables.scss";

.new-resource-form {
  width: 60%;
  margin: 2rem auto;

  @media only screen and (max-width: 1024px) {
    width: 80%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0 2rem;
  }
}

.group-select,
.resource-name,
.resource-website,
.resource-frequency,
.resource-notifications,
.resource-ip,
.ssl-frequency,
.resource-error-notification,
.resource-select-region {
  margin-top: 4rem;
}

.resource-type {
  h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  &__options {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;

    @media screen and (max-width: "1024px") {
      flex-wrap: nowrap;
      flex-direction: column;
    }

    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      flex-direction: row;
    }

    @media screen and (max-width: "520px") {
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }
}

.resource-json {
  display: flex;
  gap: 1rem;
}

.resource-ip {
  display: flex;
  // align-items: flex-end;
  gap: 1rem;
  &__caution {
    color: $color-primary;
    font-size: 1.3rem;

    button {
      background-color: transparent;
      color: #3498db;
      outline: none;
      border: none;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: darken($color: #3498db, $amount: 10%);
        text-decoration: underline;
      }
    }
  }
}

.hr {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin-top: 4rem;
}

.resource-notifications {
  background-color: $background-color-secondary;
  border-radius: 8px;

  h3 {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  &__input {
    display: flex;
    gap: 1rem;
    align-items: flex-start;

    i {
      background-color: #6c7072;
      padding: 1.2rem;
      border-radius: 4px;
      font-size: 2rem;
      cursor: pointer;
      margin-top: 0.3rem;
    }
  }

  &__create {
    background-color: transparent;
    outline: none;
    border: none;
    color: #48a7f8;
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: bold;

    &::before {
      content: "+";
      display: inline-block;
      color: #48a7f8;
      margin-right: 1rem;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.resource-save-and-create {
  margin-top: 4rem;
  text-align: center;

  button {
    background-color: transparent;
    outline: none;
    border: none;
    color: $color-primary;
    font-size: 1.6rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    &::before {
      content: "+";
      display: inline-block;
      color: $color-primary;
      margin-right: 0.5rem;
    }
  }
}

.resource-cancel {
  margin-top: 4rem;
  text-align: center;

  button {
    background-color: transparent;
    outline: none;
    border: none;
    color: $color-primary;
    font-size: 1.6rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
}

.resource-submit {
  max-width: 35rem;
  margin: 0 auto;
  margin-top: 4rem;
}

.server-table {
  // margin-bottom: 2rem;

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #2b2e2e;
    border-radius: 8px;

    td {
      background-color: #404446;
      text-align: center;
      padding: 2rem 0;
      border: 1px solid #2b2e2e;
    }

    th {
      background-color: #d85701;
      padding: 1rem 0;
      border: 1px solid #2b2e2e;
    }
  }

  a {
    border: none;
    background-color: transparent;
    color: #3498db;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    font-size: 1.3rem;
    text-decoration: none;

    &:hover {
      color: darken($color: #3498db, $amount: 10%);
      text-decoration: underline;
    }
  }
}
