.notification {
  border: 1px solid #72777a;
  border-radius: 8px;
  padding: 1.3rem;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  &__heading {
    font-size: 1.6rem;

    &__resource-name,
    &__group-name {
      font-weight: 700;
    }
  }

  &__region {
    font-size: 1.2rem;
    margin: 1.2rem 0;
  }

  &__date {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 1.2rem;
    }

    i {
      cursor: pointer;
      font-size: 2rem;
    }
  }
}
