@import "../../variables.scss";

.wrapper {
  margin-left: 250px;
  margin-right: 260px;
  margin-top: 6.6rem;
  min-height: calc(100vh - 67px);
  animation: animateOpacity 0.5s ease-in-out;

  @media only screen and (max-width: 1024px) {
    margin-right: 150px;
  }

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }

  &--border {
    border: 1px solid $background-color-secondary;
  }

  &--bg {
    background-color: $background-color-secondary;
  }
}
