.user-login__container {
  max-width: 60.5rem;
  width: 95%;
  margin: 0 auto;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.user-login {
  width: 100%;

  &__logo {
    text-align: center;
    margin-bottom: 2rem;

    img {
      width: 35rem;
      height: 5rem;
    }
  }

  &__heading {
    h1 {
      font-size: 4.8rem;
      text-align: center;
      margin-bottom: 3rem;
    }
  }

  &__divider {
    margin-top: 3rem;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;

    div {
      flex: 1;

      hr {
        height: 1px;
        background-color: #72777a;
        border: none;
      }
    }
  }

  &__oauth {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;

    @media screen and (max-width: 640px) {
      flex-direction: column;
    }
  }
}

.user-login__form {
  &__forgot-btn {
    background-color: transparent;
    border: none;
    color: #48a7f8;
    cursor: pointer;
    margin-bottom: 2.5rem;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: darken($color: #48a7f8, $amount: 20%);
    }
  }
}

.email-verified {
  margin-bottom: 2rem;
  color: #0f5132;
  background-color: #d1e7dd;
  padding: 1.3rem 1.5rem;
  border-radius: 5px;
  font-size: 1.4rem;
}
