.group-resources {
  border: 1px solid #404446;
  padding: 1.5rem;

  @media only screen and (max-width: 768px) {
    border: none;
  }

  &:not(:first-child) {
    margin-top: 1rem;
  }

  &__header {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__name {
      h2 {
        font-size: 2.4rem;
        color: #fff;

        display: flex;
        align-items: center;
        gap: 1rem;

        i {
          cursor: pointer;
          font-size: 1.7rem;
        }
      }

      p {
        color: #f83427;
        font-size: 1.25rem;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    margin-top: 1rem;
  }
}
