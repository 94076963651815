@import "../../variables.scss";

input[type="checkbox"] {
  -webkit-apperance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  outline: 0;

  // Unchecked
  font: inherit;
  color: #eee;
  width: 2rem;
  height: 2rem;
  border: 1px solid #777;
  border-radius: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="checkbox"]::before {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  transform: scale(0);
  transition: all 0.2s ease-in-out;
  background-color: #eee;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked {
  background-color: $color-primary;
  border: 1px solid $color-primary;

  &::before {
    transform: scale(1);
  }
}

input[type="checkbox"]:disabled {
  cursor: not-allowed;
  border: 1px solid #777;
}

input[type="checkbox"]:disabled:checked {
  background-color: $color-primary;
  border: 1px solid $color-primary;

  opacity: 0.5;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 1rem;
}
