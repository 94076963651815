@import "../../variables.scss";

.user-forgot__container {
  max-width: 60.5rem;
  width: 95%;
  margin: 0 auto;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.user-forgot {
  width: 100%;

  &__logo {
    text-align: center;
    margin-bottom: 5rem;

    img {
      width: 35rem;
      height: 5rem;
    }
  }

  &__heading {
    h1 {
      font-size: 4.8rem;
      text-align: center;
      margin-bottom: 3rem;
    }
  }
}

.user-forgot__form__send-mail {
  border: none;
  color: $color-primary;
  background-color: transparent;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: darken($color: $color-primary, $amount: 9%);
  }
}

.email-sent {
  margin-bottom: 2rem;
  color: #0f5132;
  background-color: #d1e7dd;
  padding: 1.3rem 1.5rem;
  border-radius: 5px;
  font-size: 1.4rem;
}
