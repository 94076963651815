.no-resource {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding: 12rem 0;

  @media only screen and (max-width: 640px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  img {
    width: 100%;
    object-fit: contain;
  }

  p {
    margin-top: 4rem;
    font-size: 1.8rem;
  }
}
