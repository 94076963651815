@import "../../variables.scss";

.notification-details {
  background-color: $background-color-secondary;
  padding: 2rem;
  border-radius: 9px;

  &__detail:not(:last-child) {
    margin-bottom: 1rem;
  }

  span {
    color: $color-primary;
    font-size: 1.4rem;
  }

  p {
    font-size: 1.8rem;
  }
}
