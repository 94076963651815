@import "../../variables.scss";

.resource {
  background-color: #303437;
  border: 1px solid #6c7072;
  padding: 1rem;
  border-radius: 10px;
  width: 47.4rem;
  min-height: 29rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
}

.resource-details {
  &__header {
    display: flex;
    justify-content: space-between;

    &__type {
      color: #cdcfd0;
      font-size: 1.6rem;

      i {
        margin-right: 0.5rem;
      }
    }

    &__name {
      font-weight: 700;
      font-size: 2.4rem;
    }
  }

  &__uri {
    margin-top: 0.5rem;
    color: #cdcfd0;
    font-size: 1.6rem;

    i {
      margin-right: 0.5rem;
    }
  }

  &__regions {
    font-size: 2rem;
    margin-top: 1.5rem;
    span {
      font-weight: bold;
    }
  }
}

.resource-actions {
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #8f8f8f;
    opacity: 0.3;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 640px) {
      margin-top: 1.5rem;
    }
  }

  &__edit {
    padding-left: 1rem;
    padding-bottom: 1rem;
    color: #ff9147;
    font-size: 2.8rem;
    display: flex;
    gap: 2rem;
    align-items: center;

    i {
      cursor: pointer;
    }
  }
}
