.group-detail {
  padding-top: 2rem;
  padding-left: 4rem;

  @media only screen and (max-width: 768px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  &__actions {
    // display: flex;
    gap: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 4rem;

    @media only screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }

  &__content {
    @media only screen and (max-width: 1024px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__heading {
      font-size: 2.4rem;
    }

    &__count {
      font-size: 1.2rem;
      color: #979c9e;

      @media screen and (max-width: 640px) {
        font-size: 1.4rem;
      }
    }

    &__description {
      font-size: 1.4rem;
    }
  }

  &__resources {
    display: flex;
    gap: 1.5rem;
    margin-top: 6rem;
    flex-wrap: wrap;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__downcount {
    margin-top: 6rem;
    text-align: center;

    p {
      font-size: 1.8rem;
      color: #f83427;
    }
  }
}
