.resource-filter {
  background-color: #303437;
  padding: 2.4rem 4.7rem;
  width: 56rem;
  border-radius: 8px;
  margin: 0 1rem;

  &__option {
    margin-bottom: 2.8rem;

    &__label {
      font-weight: 700;
      color: #fff;
      display: block;
      margin-bottom: 1rem;
    }

    &__actions {
      display: flex;
      gap: 1rem;
      margin-top: 0.5rem;

      @media only screen and (max-width: 640px) {
        flex-direction: column;
      }

      .btn-container {
        width: 228px;

        @media only screen and (max-width: 640px) {
          width: 100%;
        }
      }
    }
  }

  .loading-time {
    display: flex;
    gap: 1rem;
  }
}
