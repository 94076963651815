@import "../../variables.scss";

.user-profile {
  max-width: 60rem;
  width: 95%;
  margin: 0 auto;
  padding: 4rem 0;

  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 2rem 2rem 0 2rem;
  }

  &__subscription {
    text-align: center;
    margin-bottom: 5.5rem;

    h1 {
      font-size: 3.2rem;

      span {
        color: $color-primary;
      }
    }

    p {
      font-size: 1.8rem;
      font-weight: 400;
      margin-bottom: 1.8rem;

      span {
        font-weight: 700;
      }
    }
  }

  &__submit {
    margin: 3rem 0;
  }

  &__logo {
    text-align: center;
    margin-bottom: 4rem;

    img {
      width: 35rem;
      height: 5rem;
    }
  }
}

.user-profile__form {
  background-color: $background-color-secondary;
  border-radius: 8px;
  padding: 1rem;

  h3 {
    font-size: 2.3rem;
    margin-bottom: 2rem;
  }
}

.user-profile__notifications {
  background-color: $background-color-secondary;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 6rem;

  h3 {
    font-size: 2.3rem;
    margin-bottom: 2rem;
  }

  &__input {
    display: flex;
    gap: 1rem;
    align-items: flex-start;

    i {
      background-color: #6c7072;
      padding: 1.2rem;
      border-radius: 4px;
      font-size: 2rem;
      cursor: pointer;
      margin-top: 0.3rem;
    }
  }

  &__create {
    background-color: transparent;
    outline: none;
    border: none;
    color: $color-primary;
    font-size: 1.5rem;
    font-weight: 500;
    margin-left: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &::before {
      content: "+";
      display: inline-block;
      color: $color-primary;
      margin-right: 1rem;
    }

    &:hover {
      color: #d85701;
    }
  }
}
