.user-signup__container {
  max-width: 60.5rem;
  width: 95%;
  margin: 0 auto;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.user-signup {
  width: 100%;

  &__logo {
    text-align: center;
    margin-bottom: 2rem;

    img {
      width: 14rem;
      height: 2rem;
    }
  }

  &__text {
    text-align: center;
    margin-bottom: 2rem;

    span {
      color: #ff6600;
      vertical-align: middle;
    }
  }

  &__heading {
    h1 {
      font-size: 4.8rem;
      text-align: center;
      margin-bottom: 3rem;
    }
  }

  &__divider {
    margin-top: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;

    div {
      flex: 1;

      hr {
        height: 1px;
        background-color: #72777a;
        border: none;
      }
    }
  }

  &__oauth {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;

    @media screen and (max-width: 640px) {
      flex-direction: column;
    }
  }
}
