@import "../../variables.scss";

.input-text {
  margin-bottom: 2rem;
  flex: 1;

  &__label {
    font-weight: 700;
    color: #fff;
    display: block;
    margin-bottom: 1rem;
  }

  &__box {
    position: relative;

    &__icon {
      position: absolute;
      top: 1rem;
      left: 1rem;

      &--disabled {
        i {
          color: #404446;
        }
      }
    }

    &__input {
      outline: none;
      background-color: #202325;
      border-radius: 8px;
      color: #fff;
      border: 2px solid $background-color-secondary;
      padding: 1.4rem;
      width: 100%;
      transition: all 0.2s ease-in-out;
      font-size: 1.6rem;

      &::placeholder {
        color: #979c9e;
      }

      &:focus {
        border: 2px solid $color-primary;
      }

      &--error {
        border: 2px solid #f83427;
      }

      &--icon-padding {
        padding: 1rem 1rem 1rem 3rem;
      }

      &:disabled {
        cursor: not-allowed;
        color: #979c9e;
        &::placeholder {
          color: #404446;
        }
      }
    }
  }

  &__caption {
    font-size: 1.3rem;
    display: block;
    margin-top: 0.5rem;

    &--error {
      color: #f83427;
    }
  }
}
