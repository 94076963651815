@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
@import "./variables.scss";

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-moz-selection {
  background-color: $color-primary;
  color: #fff;
}

::selection {
  background-color: $color-primary;
  color: #fff;
}

/***************
 * Scrollbar *
 ***************/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #202325;
}

::-webkit-scrollbar-thumb {
  background: #555;
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}

html {
  font-size: 10px;

  @media only screen and (max-width: 1024px) {
    font-size: 9px;
  }

  @media only screen and (max-width: 640px) {
    font-size: 8px;
  }
}

body {
  position: relative;
  font-size: 1.6rem;
  background-color: $background-color-primary;
  color: $base-font-color;
  line-height: 1.5;
  font-family: $font-family;
}

.button-action {
  background-color: transparent;
  outline: none;
  border: none;
  color: $color-primary;
  font-size: 1.6rem;
  font-weight: 500;
  margin-left: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #d85701;
  }
}

.custom-date {
  margin-top: 1rem;
  display: flex;
  gap: 1.5rem;

  div {
    span {
      display: block;
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    input {
      padding: 1rem;
      background-color: #202325;
      border: none;
      color: #fff;
      border-radius: 8px;
      font: inherit;
      font-size: 1.3rem;
    }
  }
}

.custom-date {
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
    cursor: pointer;
  }
}
@keyframes animateOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.question-btn {
  text-align: center;
  font-size: 1.6rem;
  p {
    color: #979c9e;
  }

  button {
    cursor: pointer;
    border: none;
    color: #ff6600;
    background-color: transparent;
    font: inherit;
    font-size: 1.6rem;
    font-weight: 700;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: darken($color: #ff6600, $amount: 9%);
    }
  }
}

// // MEDIA QUERIES BREAKPOINTS
// @media only screen and (max-width: 1024px) {
// }

// @media only screen and (max-width: 768px) {
// }

// @media only screen and (max-width: 640px) {
// }
