.resource-status {
  background-color: #303437;
  padding: 1rem;
  min-height: 226px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    min-height: auto;
  }

  &__header {
    div {
      display: flex;
      justify-content: space-between;
      gap: 1.5rem;

      i {
        margin-top: 0.4rem;
      }
    }
    @media only screen and (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__type {
    color: #cdcfd0;
    font-size: 1.2rem;
    margin-bottom: 1.6rem;

    @media screen and (max-width: 640px) {
      font-size: 1.4rem;
    }

    i {
      margin-right: 0.5rem;
    }
  }

  h3 {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }

  &__regions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &.disabled {
    h3,
    .resource-status__type,
    .resource-status__regions {
      opacity: 0.3;
    }
  }
}

.ssl-expiry {
  font-size: 2.4rem;
  color: #23c16b;
  font-weight: 700;
}

.region-name {
  display: flex;
  gap: 1rem;

  &__icon {
    display: flex;
    align-items: center;
    gap: 0.4rem;

    span {
      font-weight: 700;
      font-size: 1.6rem;
    }
  }
}

.last-checked {
  color: #979c9e;
  font-size: 1.2rem;

  @media screen and (max-width: 640px) {
    font-size: 1.3rem;
  }
}
