@import "../../variables.scss";

.edit-group {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 4rem;

  @media only screen and (max-width: 768px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  &__details {
    max-width: 67rem;
  }

  &__description {
    span {
      font-weight: 700;
      color: #fff;
      display: block;
      margin-bottom: 1rem;
    }

    textarea {
      resize: none;
      font-size: 1.6rem;
      min-height: 150px;
      min-width: 100%;
      max-width: 100%;
      height: 100%;
      padding: 1.6rem;
      outline: none;
      background-color: #202325;
      border-radius: 8px;
      color: #fff;
      border: 2px solid $background-color-secondary;
      transition: all 0.2s ease-in-out;
      font-family: inherit;

      @media screen and (max-width: 640px) {
        font-size: 1.5rem;
      }

      &::placeholder {
        color: #979c9e;
      }

      &:focus {
        border: 2px solid $color-primary;
      }
    }
  }

  &__create {
    margin-top: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      gap: 10rem;
    }

    @media screen and (max-width: 640px) {
      margin-top: 4rem;
      gap: 5rem;
    }

    button {
      background-color: transparent;
      outline: none;
      border: none;
      color: $color-primary;
      font-size: 1.5rem;
      font-weight: 500;
      margin-left: 1.5rem;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &::before {
        content: "+";
        display: inline-block;
        color: $color-primary;
        margin-right: 1rem;
      }

      &:hover {
        color: #d85701;
      }
    }

    p {
      color: #828282;
    }
  }

  &__resources {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;

    margin-top: 6rem;
  }

  &__submit {
    margin-top: 6rem;

    @media only screen and (max-width: 768px) {
      button {
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 640px) {
      margin-top: 3rem;
    }
  }

  &__group-delete {
    margin-top: 1.6rem;
    text-align: left;
    @media only screen and (max-width: 768px) {
      text-align: center;
    }
  }
}
