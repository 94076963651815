@import "../../variables.scss";

.group {
  border: 1px solid #979c9e;
  background-color: #404446;
  padding: 1rem;
  width: 274px;
  height: 260px;
  border-radius: 13px;
  cursor: pointer;
  margin: 0 auto;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 150px;
    height: 140px;
  }

  &__name {
    font-size: 3.2rem;
    text-transform: capitalize;
    font-weight: 700;
    text-align: center;

    @media only screen and (max-width: 768px) {
      font-size: 2rem;
    }
  }

  &__resources {
    color: $color-primary;
    font-size: 2.4rem;
    font-weight: 700;
    text-align: center;

    @media only screen and (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  &__delete {
    position: absolute;
    bottom: 1rem;
    right: 1.5rem;

    i {
      font-size: 1.9rem;
      color: #e74c3c;
    }
  }
}
