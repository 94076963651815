@import "../../variables.scss";

.groups-list {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(274px, 1fr));
  grid-gap: 6rem;

  padding: 3rem 3rem 0 3rem;

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-column-gap: 2rem;
    padding: 3rem 1rem 3rem 1rem;
  }

  &__create {
    border: 1px solid #979c9e;
    background-color: #404446;
    padding: 1rem;
    width: 274px;
    height: 260px;
    border-radius: 13px;
    color: $color-primary;
    font-size: 2.4rem;
    font-weight: 700;
    cursor: pointer;
    margin: 0 auto;

    @media only screen and (max-width: 768px) {
      width: 150px;
      height: 140px;

      font-size: 2rem;
    }
  }
}
