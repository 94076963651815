@import "../../variables.scss";

.create-group-form {
  position: relative;
  z-index: 1000;
  max-width: 60rem;
  width: 90%;
  margin: 0 auto;
  background-color: $background-color-secondary;
  border: 1px solid #6c7072;
  border-radius: 10px;
  padding: 2rem 3rem;

  &__name {
    margin-top: 1rem;
  }

  &__description {
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: 100%;

    span {
      font-weight: 700;
      color: #fff;
      display: block;
      margin-bottom: 1rem;
    }

    textarea {
      resize: none;
      font-size: 1.6rem;
      min-height: 100px;
      min-width: 100%;
      max-width: 100%;
      height: 100%;
      padding: 1.6rem;
      outline: none;
      background-color: #202325;
      border-radius: 8px;
      color: #fff;
      border: 2px solid $background-color-secondary;
      transition: all 0.2s ease-in-out;
      font-family: inherit;

      &::placeholder {
        color: #979c9e;
      }

      &:focus {
        border: 2px solid $color-primary;
      }
    }
  }
}

.close-create-group {
  position: absolute;
  right: 3rem;
  top: 2rem;

  i {
    cursor: pointer;
  }
}
