@import "../../variables.scss";

.navigation {
  position: fixed;
  top: 0;
  z-index: 1001;

  height: 100vh;
  background-color: $background-color-secondary;
  padding-top: 3rem;
  padding-left: 20px;
  transition: all 0.3s ease-in-out;
  width: 67px;

  @media only screen and (max-width: 768px) {
    transform: translateX(-100%);
  }

  &--expanded {
    width: 244px;

    @media only screen and (max-width: 768px) {
      transform: translateX(0);
      width: 300px;
    }

    .close {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    .cross {
      display: none;
      cursor: pointer;

      @media only screen and (max-width: 768px) {
        display: block;
        font-size: 2rem;
        margin-right: 1rem;
      }
    }
  }

  &__logo {
    display: flex;
    gap: 2rem;
    margin-bottom: 7rem;
    align-items: center;

    img {
      height: 22px;
      width: 158px;
      object-fit: contain;
      animation: animateOpacity 0.3s ease-in-out;
    }

    span {
      display: flex;
      align-items: center;
      // font-size: 2rem;
      height: 22px;
      animation: animateOpacity 0.6s ease-in-out;

      i {
        cursor: pointer;
      }
    }
  }
}

.navigation__list {
  list-style: none;

  li {
    font-size: 2rem;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  &__link {
    text-decoration: none;
    color: #fff;

    &__text {
      animation: animateOpacity 0.3s ease-in-out;
    }

    &--active {
      transition: all 0.3s ease-in-out;
      color: $color-primary;
      text-decoration: none;
    }
  }
}

.logout-btn {
  outline: none;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  position: relative;
}

.align-menu-items {
  display: block;
  position: absolute;
  top: -5px;
  left: 18px;

  @media screen and (max-width: 640px) {
    top: -3.5px;
  }
}
