.auth-button {
  position: relative;
  width: 36.6rem;

  @media screen and (max-width: 640px) {
    width: 100%;
  }

  &__icon {
    position: absolute;
    top: 50%;
    font-size: 1.8rem;
    transform: translate(0, -50%);
    left: 1.5rem;
    display: flex;
    align-items: center;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;

  outline: none;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  font: inherit;
  transition: all 0.3s ease-in-out;
  padding: 0.8rem;
  background-color: transparent;
  font-size: 1.4rem;
  border: 2px solid #303437;
  color: #fff;

  &--google:hover {
    border-color: #db4437;
  }

  &--facebook:hover {
    border-color: #4267b2;
  }

  &--github:hover {
    border-color: #555555;
  }
}
