@import "../../variables.scss";

.switch {
  width: 5.6rem;
  height: 3.2rem;
  border-radius: 32px;
  background-color: $color-primary;
  border: 1px solid $color-primary;
  cursor: pointer;
  position: relative;

  &--active {
    .switch__box {
      // transform: translateX(100%);
      left: 50%;
    }
  }

  &--inactive {
    background-color: #6c7072;
    border: 1px solid #6c7072;

    .switch__box {
      // transform: translateX(0);
      left: 2px;
    }
  }

  &:disabled {
    border: 1px solid $background-color-secondary;
    background-color: #000;
    cursor: default;

    .switch__box {
      background-color: $background-color-secondary;
    }
  }

  &--active:disabled {
    background-color: #202325;
  }

  &__box {
    border-radius: 50%;
    background-color: #fff;
    height: 2.5rem;
    width: 2.5rem;
    transition: all 0.3s ease-in-out;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
