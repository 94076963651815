@import "../../variables.scss";

.confirmation-modal {
  z-index: 1000;
  max-width: 60rem;
  width: 90%;
  margin: 0 auto;
  background-color: $background-color-secondary;
  border: 1px solid #6c7072;
  border-radius: 10px;
  padding: 2rem 3rem;

  &__heading {
    &::after {
      content: "";
      display: block;
      height: 2px;
      background-color: #555;
      margin: 1rem 0;
    }
  }

  &__title {
    font-size: 1.5rem;
    margin: 2rem 0 1.5rem 0;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
