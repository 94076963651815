.alert {
  padding: 1.5rem;
  margin-top: 3rem;
  border-radius: 8px;

  &.primary {
    background-color: #cce5ff;
    color: #004085;
  }

  &.info {
    background-color: #d1ecf1;
    color: #0c5460;
  }

  &.warning {
    background-color: #fff3cd;
    color: #856404;
  }

  &.success {
    background-color: #d4edda;
    color: #155724;
  }
}
