@import "../../variables.scss";

.button {
  position: relative;

  &__icon {
    position: absolute;
    top: 50%;
    font-size: 1.8rem;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;

    &--primary {
      color: #fff;
    }

    &--secondary {
      color: #d85701;
    }

    &--outline {
      color: #ff9147;
    }

    &--transparent {
      color: #ff9147;
    }

    &--disabled {
      color: #6c7072;
    }

    &--left {
      left: 7%;
    }

    &--right {
      right: 7%;
    }
  }

  .btn {
    display: block;
    width: 100%;
    text-align: center;
    outline: none;
    cursor: pointer;
    border-radius: 0.6rem;
    transition: all 0.3s ease-in-out;
    padding: 1.3rem 0;
    font-size: 1.4rem;

    display: flex;
    justify-content: center;
    align-items: center;

    &--large {
      max-width: 327px;
    }

    &--medium {
      max-width: 218px;
    }

    &--small {
      max-width: 168px;
      height: 32px;
    }

    &__icon--left {
      padding-left: 15%;
    }

    &__icon--right {
      padding-right: 15%;
    }

    &--primary {
      background-color: $color-primary;
      border: 1px solid $color-primary;
      color: #fff;

      &:hover {
        background-color: #d85701;
        border: 1px solid #d85701;
      }

      &:disabled {
        @extend .btn--disabled;
      }
    }

    &--secondary {
      background-color: #ffd3b6;
      color: #d85701;
      border: 1px solid #ffd3b6;

      &:hover {
        background-color: #ff9147;
        border: 1px solid #ff9147;
        color: #d85701;
      }

      &:disabled {
        @extend .btn--disabled;
      }
    }

    &--outline {
      background-color: transparent;
      color: #ff9147;
      border: 1px solid #ff9147;

      &:hover {
        color: $color-primary;
        border: 1px solid $color-primary;
      }

      &:disabled {
        @extend .btn--disabled;
      }
    }

    &--transparent {
      background-color: transparent;
      border: none;
      color: #ff9147;

      &:hover {
        background-color: #ffd3b6;
        color: $color-primary;
        border: 1px solid #ffd3b6;
      }

      &:disabled {
        background-color: transparent;
        color: #6c7072;
        border: none;
        cursor: not-allowed;
      }
    }
  }
}

.btn--disabled {
  background-color: $background-color-secondary;
  cursor: default;
  color: #6c7072;
  border: 1px solid $background-color-secondary;
}

.btn--outline:hover ~ .button__icon {
  color: $color-primary;
}

.btn--outline:disabled ~ .button__icon {
  color: #6c7072;
}

.btn--transparent:hover ~ .button__icon {
  color: $color-primary;
}

.btn--transparent:disabled ~ .button__icon {
  color: #6c7072;
}
