@import "../../variables.scss";

.resource-view {
  padding: 3rem 3rem 0 3rem;

  &__details {
    display: flex;
    justify-content: space-between;

    &__type {
      i {
        margin-right: 1rem;
      }

      p {
        color: #cdcfd0;
        font-size: 1.6rem;
      }
    }

    &__group {
      text-align: right;

      p {
        color: $color-primary;
        font-size: 1.6rem;
        font-weight: 700;
      }

      span {
        color: #949494;
        font-size: 1.2rem;
      }
    }
  }

  &__ssl {
    margin-top: 10rem;
    text-align: center;

    h3 {
      margin-top: 3rem;
      p {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-size: 3rem;

        span {
          font-weight: 500;
          font-size: 1.8rem;
          color: $color-primary;
        }
      }
    }
  }

  &__chart {
    margin-top: 24px;

    &__average {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2.5rem;
      padding-left: 5rem;

      p {
        font-size: 2.1rem;
        font-weight: 700;
        color: #fff;
      }

      span {
        color: #cdcfd0;
        font-size: 1.36rem;
      }
    }

    &__container {
      border-radius: 11px;
      padding: 2rem 2rem 2rem 1rem;
      background-color: #202325;
    }

    &__time {
      display: flex;
      justify-content: space-between;

      color: #fff;
      font-size: 1.3rem;
      padding: 0 2rem 0 6rem;
      margin-bottom: 1rem;
      margin-top: -2rem;
    }

    &__region {
      text-align: center;
      font-size: 2rem;
      font-weight: 700;
      color: #fff;
    }
  }

  &__actions {
    margin-top: 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      position: relative;

      div {
        position: absolute;
        height: 13px;
        width: 13px;
        z-index: 99;
        top: 10px;
        right: 11px;

        svg {
          stroke: $color-primary;
        }
      }
    }

    /***************
    * Date Picker *
    ***************/

    label {
      padding: 1rem 1.6rem;
      background-color: #202325;
      border-radius: 8px;
    }

    input {
      background-color: #202325;
      outline: none;
      border: none;
      color: $color-primary;
      font-family: inherit;
      font-size: 1.4rem;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      background-image: url("../../assets/calendar.svg");
      margin-left: -8px;
      margin-top: 1px;
      cursor: pointer;
    }

    /***************
    * Filter Button *
    ***************/

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      background: transparent;
      outline: none;
      padding: 1.4rem 1rem;
      border: 2px solid #777;
      color: #888;
      width: 190px;
      font-size: 1.6rem;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      @media screen and (max-width: 640px) {
        padding: 1rem;
        width: 130px;
      }

      &:hover {
        border: 2px solid rgb(187, 184, 184);
        color: rgb(187, 184, 184);

        i {
          color: rgb(187, 184, 184);
        }
      }

      i {
        transition: all 0.3s ease-in-out;
        color: #888;
      }
    }
  }

  &__table {
    margin: 40px 0px;

    table {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #2b2e2e;
      border-radius: 8px;

      td {
        background-color: #404446;
        text-align: center;
        padding: 2rem 0;
        border: 1px solid #2b2e2e;
      }

      th {
        background-color: #d85701;
        padding: 1rem 0;
        border: 1px solid #2b2e2e;
      }
    }

    &__page-actions {
      display: flex;
      gap: 6rem;
      justify-content: center;
      align-items: center;

      margin-top: 6rem;
      font-size: 2.5rem;

      span {
        font-size: 1.8rem;
      }

      i {
        cursor: pointer;

        &.disabled {
          color: #444;
          cursor: default;
        }
      }
    }
  }
}

/***************
 * Swiper Styles Override *
 ***************/
.swiper-slide {
  width: 540px !important;

  @media screen and (max-width: 960px) {
    width: 100% !important;
  }
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1.5rem !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  border-radius: 100% !important;
  width: 30px !important;
  height: 30px !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.icon-tick {
  color: #23c16b;
}

.ssl-details {
  font-size: 6vw;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  @media only screen and (max-width: 768px) {
    // font-size: 8rem;
    font-size: 10vw;
  }

  p {
    display: flex;
    flex-direction: column;

    span {
      font-weight: 500;
      font-size: 1.8rem;
      color: #999;
    }
  }
}

.ssl-down--unable {
  font-size: 3rem;
  color: $color-primary;
  width: 80%;
  margin: 0 auto;

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 0;
  }
}

.ssl-down {
  font-size: 3.5rem;
  color: $color-primary;

  @media screen and (max-width: 640px) {
    font-size: 3rem;
  }
}
