@import "../../variables.scss";

.input-dropdown {
  &__label {
    font-weight: 700;
    color: #fff;
    display: block;
    margin-bottom: 1rem;
  }

  &__caption {
    font-size: 1.3rem;
    display: block;
    margin-top: 0.5rem;

    &--error {
      color: #f83427;
    }
  }

  &__box {
    background-color: #202325;
    padding: 0 1.6rem;
    border-radius: 8px;
    outline: none;
    border: 2px solid $background-color-secondary;
    font-size: 1.3rem;
    transition: all 0.3s ease-in;
    width: 100%;

    &--error {
      border: 2px solid #f83427;
    }

    &__label {
      font-weight: 700;
      color: #fff;
      display: block;
      margin-bottom: 1rem;
    }

    &--visible {
      border: 2px solid $color-primary;
      transition: all 0.3s ease-in-out;
    }

    &__selected {
      width: 100%;
      height: 100%;
      cursor: pointer;
      padding: 1.575rem 0;
      position: relative;
    }

    &--disabled {
      color: #404446;
      cursor: default;
    }

    &__list {
      list-style: none;
      max-height: 0;
      transition: all 0.3s ease-in-out;
      overflow-y: hidden;

      &:before {
        content: "";
        display: block;
        height: 2px;
        background-color: $background-color-secondary;
      }

      &--visible {
        max-height: 200px;
        overflow-y: hidden;
      }

      li {
        cursor: pointer;
        padding: 1rem;
        transition: all 0.1s ease-in-out;

        &:hover {
          background-color: lighten($color: #202325, $amount: 10%);
        }
      }
    }

    &__new {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem 0;

      span {
        color: $color-primary;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: darken($color: $color-primary, $amount: 10%);
        }
      }
    }
  }
}

.items-list {
  max-height: 120px;
  overflow-y: hidden;

  &--scrollable {
    overflow-y: scroll;
  }
}

.arrow-icon {
  width: 1.2rem;
  height: 0.6rem;
  position: absolute;
  right: 0.2rem;
  top: 1.7rem;

  svg {
    animation: animateSvg 0.3s ease-in-out;
    stroke: #fff;
  }
}

.item-with-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@keyframes animateSvg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
