@import "../../variables.scss";

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  // background-color: #202325;
  background-color: transparent;
  margin: 0;
  cursor: pointer;

  // Custom unchecked
  font: inherit;
  width: 2rem;
  height: 2rem;
  border: 1px solid #888;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="radio"]::before {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  transform: scale(0);
  transition: all 0.2s ease-in-out;
  background-color: #fff;
}

input[type="radio"]:checked {
  background-color: $color-primary;
  border: 1px solid $color-primary;

  &::before {
    transform: scale(1);
  }
}

input[type="radio"]:disabled {
  cursor: default;
  border: 1px solid darken($color: #eee, $amount: 50%);
}

input[type="radio"]:checked:disabled {
  background-color: #202325;

  &::before {
    background-color: $background-color-secondary;
  }
}

.radio-button {
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;

  &__label {
    font-size: 1.6rem;
  }
}
