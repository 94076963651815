@import "../../variables.scss";

.header {
  padding: 1rem 2rem;
  border-bottom: 1px solid #404446;
  margin-left: 67px;
  transition: all 0.3s ease-in-out;
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
  background-color: $background-color-primary;
  z-index: 1000;
  animation: animateOpacity 0.5s ease-in-out;

  position: fixed;
  top: 0;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }

  span {
    font-size: 2.4rem;
    font-weight: 700;

    @media only screen and (max-width: 768px) {
      margin-right: auto;
    }
  }

  &--expanded {
    margin-left: 243px;

    @media only screen and (max-width: 768px) {
      margin-left: 0;
    }
  }
}

.mobile-menu {
  display: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    display: block;
  }
}
